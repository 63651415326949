import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Over the years I had the opportunity to work on several projects that used GeoNode. Whenever possible, I took the
opportunity to upstream some of the improvements that were made internally. This eventually to me being given commit
rights to the main GeoNode repository. My main contribution to the project has been on some overall bug fixing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      